const preguntas = [
    {
        step: 3,
        questions: [
            {
                id: 1,
                title: "¿Qué rol(es) desempeña(s) en tu establecimiento?: (Puedes marcar más de una alternativa)",
                type: "selection",
                multiple: true,
                options: [
                    "Coordinador/a",
                    "Jefe/a de departamento",
                    "Profesor/a de asignatura",
                    "Profesor/a jefe",
                    "Codocencia o apoyo a la docencia",
                    "Encargado/a de convivencia escolar",
                    "Encargado/a de apoyo al aprendizaje/PIE",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 7,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 2,
                title: "Selecciona la cantidad de años que llevas trabajando en tu establecimiento:",
                type: "selection",
                multiple: false,
                options: [
                    "1 año",
                    "2 años",
                    "3 años",
                    "4 años",
                    "5 años",
                    "6 años",
                    "7 años",
                    "8 años",
                    "9 años",
                    "10 años",
                    "más de 10 años"
                ],
                openQuestion: true,
                triggerIndex: 10,
                titleOpenQuestion: "Indica cuántos",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 3,
                title: "Si ingresaste a la Carrera Docente, menciona el tramo de evaluación en el que te encuentras:",
                type: "selection",
                multiple: false,
                options: [
                    "Inicial",
                    "Temprano",
                    "Avanzado",
                    "Experto I",
                    "Experto II",
                    "Acceso",
                    "No aplica en mi caso"
                ],
                answers: [],
            },
            {
                id: 4,
                title: "Marca todos los niveles de enseñanza en los que impartes clases. Al seleccionar un curso " +
                    "podrás especificar en qué asignatura. Puedes incluir más de un curso y más " +
                    "de una asignatura según sea el caso.",
                type: "selection",
                multiple: true,
                options: [
                    "1ero básico",
                    "2do básico",
                    "3ero básico",
                    "4to básico",
                    "5to básico",
                    "6to básico",
                    "7mo básico",
                    "8vo básico",
                    "I medio",
                    "II medio",
                    "III medio",
                    "IV medio"
                ],
                openQuestion: true,
                triggerIndex: null, // todos incluyen respuesta abierta
                titleOpenQuestion: "Indica",
                answers: [],
                answerOpenQuestion: []
            },
            {
                id: 5,
                title: "Indica cuál es tu formación inicial en pregrado",
                type: "text",
                options: null,
                answers: null
            },
            {
                id: 6,
                title: "Indica en cuáles de las siguientes instancias de formación continua has participado o estás participando.",
                type: "selection",
                multiple: true,
                options: [
                    "Diplomado.",
                    "Postítulo.",
                    "Cursos de especialización.",
                    "Postgrado."
                ],
                openQuestion: true,
                triggerIndex: -1, // todos incluyen respuesta abierta
                titleOpenQuestion: "Indica",
                answers: [],
                answerOpenQuestion: []
            },
            {
                id: 7,
                title: "¿En qué departamento o área disciplinar te desempeñas?",
                type: "selection",
                multiple: true,
                options: [
                    "Lengua y Literatura .",
                    "Matemática.",
                    "Inglés",
                    "Ciencias Sociales ",
                    "Ciencias Naturales",
                    "Ed. Física",
                    "Artes Visuales y Música",
                    "Filosofía o religión ",
                    "Educación General Básica ",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 9,
                titleOpenQuestion: "Indica",
                answers: [],
                answerOpenQuestion: []
            },
        ],
    },
    {
        step: 4,
        questions: [
            {
                id: 7,
                title: "¿Qué entiendes por evaluación? Elige las dos definiciones que mejor te representen:",
                type: "selection",
                multiple: true,
                max_selection: 2,
                options: [
                    "Un proceso de aprendizaje para profesores y estudiantes",
                    "Un proceso que sirve para la toma de decisiones pedagógicas",
                    "Un indicador del logro de los objetivos alcanzados por las y los estudiantes",
                    "Una forma de evidenciar el aprendizaje de las y los estudiantes",
                    "Una forma de certificar los aprendizajes adquiridos por las y los estudiantes mediante una" +
                    "nota",
                    "Una forma de retroalimentar a las y los estudiantes",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 6,
                titleOpenQuestion: "¿Cuál?",
                answers: []
            },
            {
                id: 8,
                title: " De acuerdo con tu experiencia ¿Cuál crees que es el principal propósito de la evaluación? Arrastra " +
                    "las siguientes opciones desde la que te parezca más importante hasta la menos importante. Si " +
                    "consideras que falta alguna opción puedes incluirla en el cuadro que dice Otro.",
                options: [
                    {id: 0, title: "Entregar información sobre la calidad de la enseñanza"},
                    {id: 1, title: "Mejorar el proceso de enseñanza y aprendizaje"},
                    {id: 2, title: "Avanzar en el logro de objetivos de aprendizaje"},
                    {
                        id: 3,
                        title: "Entregar información sobre el nivel de logro de los estudiantes durante el proceso de aprendizaje"
                    },
                    {id: 4, title: "Retroalimentar a las y los estudiantes sobre su aprendizaje"},
                    {id: 5, title: "Una forma de fundamentar las decisiones pedagógicas"},
                    {
                        id: 6,
                        title: "Una forma de involucrar a los demás actores de la comunidad educativa (apoderados, UTP, estudiantes, directivos)"
                    },
                    {id: 7, title: "Otro"}
                ],
                type: "sorteable",
                openQuestion: true,
                triggerIndex: 7,
                titleOpenQuestion: "",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 9,
                title: "¿Cuáles te parecen que son los conocimientos más importantes que debiese tener un/a docente " +
                    "respecto de la evaluación? Ordena las siguientes opciones desde la que te parezca más importante " +
                    "hasta la menos importante. Si consideras que falta alguna opción puedes incluirla en Otro",
                options: [
                    {id: 0, title: "Construcción de instrumentos válidos para dar cuenta de logros de aprendizaje"},
                    {id: 1, title: "Construcción de evaluaciones que aborden dimensiones socioemocionales"},
                    {id: 2, title: "Vínculo de instrumentos creados con el currículum nacional"},
                    {id: 3, title: "Adaptación de instrumentos para atender a la diversidad de estudiantes"},
                    {id: 4, title: "Adecuación de las evaluaciones al contexto"},
                    {
                        id: 5,
                        title: "Análisis de evidencia de aprendizajes para orientar la toma de decisiones pedagógicas"
                    },
                    {id: 6, title: "Otro"}
                ],
                type: "sorteable",
                openQuestion: true,
                triggerIndex: 6,
                titleOpenQuestion: "",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 10,
                title: "¿Qué tan preparado/a te sientes para realizar las siguientes acciones?:",
                type: "rubric",
                options: [
                    {
                        id: 0,
                        title: "Diseñar diferentes tipos de instrumentos de evaluación (pruebas, escalas de apreciación, rúbricas, entre otros)"
                    },
                    {
                        id: 1,
                        title: "Realizar adecuaciones a los instrumentos de evaluación, en función de las características de mis estudiantes"
                    },
                    {id: 2, title: "Retroalimentar los aprendizajes y/o desempeños de mis estudiantes"},
                    {id: 3, title: "Corrección de instrumentos de evaluación (pruebas, ensayos, etc)"},
                    {
                        id: 4,
                        title: "Tomar decisiones pedagógicas para definir acciones que permitan revertir aprendizajes descendidos en mis estudiantes"
                    }
                ],
                options_column: ["Nada preparado", "Poco preparado", "Preparado", "Muy preparado"],
                answers: []
            },
            {
                id: 11,
                title: "A continuación, te presentamos un conjunto de desempeños del decreto 67, que refiere a las normas " +
                    "nacionales sobre evaluación, calificación y promoción de estudiantes. Seleccionas todas las actividades " +
                    "que <strong>más implementas</strong>.",
                type: "selection",
                multiple: true,
                options: [
                    "Elaborar distintos tipos de evaluaciones para responder a la diversidad de estudiantes",
                    "Elaborar instrumentos situados que se relacionen con intereses de estudiantes",
                    "Elaborar instrumentos que consideren conocimientos relevantes y útiles para las y los estudiantes",
                    "Evaluar a estudiantes con necesidades educativas especiales (NEE)",
                    "Evaluar a estudiantes con dificultades socioemocionales",
                    "Explicitar a mis estudiantes los objetivos de aprendizaje y criterios de logro",
                    "Evidenciar los aprendizajes mientras ocurren a partir de diferentes recursos (utilización cuaderno, " +
                    "bitácoras, plenarias, estrategias para visibilizar el pensamiento, etc)",
                    "Desarrollar evaluaciones diagnósticas",
                    "Desarrollar evaluaciones formativas",
                    "Retroalimentar efectiva y oportunamente el proceso de aprendizaje de las y los estudiantes",
                    "Adaptar las planificaciones y estrategias de enseñanza en virtud de resultados obtenidos por estudiantes",
                    "Diseñar otras modalidades de evaluación como autoevaluación o coevaluación",
                    "Enseñar a las y los estudiantes a evaluarse a sí mismos y a otros"
                ],
                answers: []
            },
            {
                id: 12,
                title: "A continuación, te presentamos un conjunto de desempeños del decreto 67, que refiere a las normas " +
                    "nacionales sobre evaluación, calificación y promoción de estudiantes. Selecciona las actividades que " +
                    "<strong>más dificultades</strong> tienes para implementar. " +
                    "Si ninguna opción te representa, " +
                    "puedes dejarla en blanco.",
                type: "selection",
                multiple: true,
                options: [
                    "Elaborar distintos tipos de evaluaciones para responder a la diversidad de estudiantes",
                    "Elaborar instrumentos situados que se relacionen con intereses de estudiantes",
                    "Elaborar instrumentos que consideren conocimientos relevantes y útiles para las y los estudiantes",
                    "Evaluar a estudiantes con necesidades educativas especiales (NEE)",
                    "Evaluar a estudiantes con dificultades socioemocionales",
                    "Explicitar a mis estudiantes los objetivos de aprendizaje y criterios de logro",
                    "Evidenciar los aprendizajes mientras ocurren a partir de diferentes recursos (utilización de cuaderno, " +
                    "bitácoras, plenarias, estrategias para visibilizar el pensamiento, etc)",
                    "Desarrollar evaluaciones diagnósticas",
                    "Desarrollar evaluaciones formativas",
                    "Retroalimentar efectiva y oportunamente el proceso de aprendizaje de las y los estudiantes",
                    "Adaptar las planificaciones y estrategias de enseñanza en virtud de resultados obtenidos por estudiantes",
                    "Diseñar otras modalidades de evaluación como autoevaluación o coevaluación",
                    "Enseñar a las y los estudiantes a evaluarse a sí mismos y a otros"
                ],
                answers: []
            },
            {
                id: 13,
                title: "A continuación, te presentamos un conjunto de desempeños del decreto 67, que refiere a las normas " +
                    "nacionales sobre evaluación, calificación y promoción de estudiantes. Selecciona las actividades que " +
                    "te parecen <strong>más relevantes</strong> de implementar. " +
                    "Si ninguna opción te representa, " +
                    "puedes dejarla en blanco.",
                type: "selection",
                multiple: true,
                options: [
                    "Elaborar distintos tipos de evaluaciones para responder a la diversidad de estudiantes",
                    "Elaborar instrumentos situados que se relacionen con intereses de estudiantes",
                    "Elaborar instrumentos que consideren conocimientos relevantes y útiles para las y los estudiantes",
                    "Evaluar a estudiantes con necesidades educativas especiales (NEE)",
                    "Evaluar a estudiantes con dificultades socioemocionales",
                    "Explicitar a mis estudiantes los objetivos de aprendizaje y criterios de logro",
                    "Evidenciar los aprendizajes mientras ocurren a partir de diferentes recursos (utilización de cuaderno, " +
                    "bitácoras, plenarias, estrategias para visibilizar el pensamiento, etc)",
                    "Desarrollar evaluaciones diagnósticas",
                    "Desarrollar evaluaciones formativas",
                    "Retroalimentar efectiva y oportunamente el proceso de aprendizaje de las y los estudiantes",
                    "Adaptar las planificaciones y estrategias de enseñanza en virtud de resultados obtenidos por estudiantes",
                    "Diseñar otras modalidades de evaluación como autoevaluación o coevaluación",
                    "Enseñar a las y los estudiantes a evaluarse a sí mismos y a otros"
                ],
                answers: []
            },
        ]
    },
    {
        step: 5,
        questions: [
            {
                id: 14,
                title: "¿En qué te basas principalmente cuando diseñas un instrumento evaluativo? (marca todas las " +
                    "opciones que consideres necesarias)",
                type: "selection",
                multiple: true,
                options: [
                    "Bases curriculares",
                    "Planes y programas",
                    "Textos escolares",
                    "Tus conocimientos en la materia",
                    "Repositorio de instrumentos personales",
                    "Repositorio de instrumentos del departamento",
                    "Repositorio de instrumentos externos",
                    "Intereses de mis estudiantes",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 8,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 15,
                title: "La creación de tus instrumentos es una tarea que generalmente realizas: (Marca todas las opciones " +
                    "que te identifiquen)",
                type: "selection",
                multiple: true,
                options: [
                    "De forma individual.",
                    "De forma grupal con colegas de mi asignatura",
                    "De forma grupal con colegas de otras asignaturas",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 3,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 16,
                title: "Al construir tus instrumentos ¿Recibes algún apoyo o retroalimentación externa?",
                type: "selection",
                multiple: false,
                options: [
                    "No (no es necesario que responda las siguientes dos preguntas)",
                    "Sí (continúe a la siguientes dos preguntas)"
                ],
                openQuestion: false,
                triggerIndex: 1,
                titleOpenQuestion: "¿De quién? y ¿En qué consiste este apoyo?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 17,
                title: "Al construir tus instrumentos. ¿De quién recibes algún apoyo? (Solo aplica si respondiste sí a la pregunta anterior).",
                type: "selection",
                multiple: true,
                options: [
                    "Coordinador/a de ciclo",
                    "Coordinador académico",
                    "Jefe de UTP",
                    "Jefe de departamento",
                    "Equipo de apoyo pedagógico (psicopedagogos/as, educadores/as diferenciales)",
                    "Colegas de la misma disciplina",
                    "Colegas de otras disciplinas",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 7,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 18,
                title: "Al construir tus instrumentos. ¿En qué consiste el apoyo? <strong>(Solo aplica si respondiste a las preguntas anteriores)</strong>.",
                type: "selection",
                multiple: true,
                options: [
                    "Construcción de instrumentos",
                    "Ajuste de aspectos formales de los instrumentos (redacción, letra, imagen, gráfico, etc)",
                    "Flexibilizar la aplicación de un instrumento por situación particular del estudiante",
                    "Adecuar a las características de las y los estudiantes",
                    "Revisión de evaluaciones diferenciadas para NEE",
                    "Otro",
                ],
                openQuestion: true,
                triggerIndex: 5,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 19,
                title: "¿Cuáles de las siguientes actividades te toma más tiempo realizar? (Marca las 3 actividades " +
                    "principales)",
                type: "selection",
                multiple: true,
                max_selection: 3,
                options: [
                    "Preparación de clases",
                    "Planificación",
                    "Creación de pruebas y/o trabajos prácticos",
                    "Adaptación de instrumentos para estudiantes estudiantes con NEE",
                    "Adaptación de instrumentos para estudiantes con dificultades socioemocionales",
                    "Aplicación de pruebas",
                    "Corrección de pruebas y/o trabajos prácticos",
                    "Retroalimentación a estudiantes",
                    "Implementación de actividades de autoevaluación de estudiantes",
                    "Implementación de instancias de evaluación entre pares",
                    "Actividades remediales o de reforzamiento",
                    "Otra"
                ],
                openQuestion: true,
                triggerIndex: 11,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 20,
                title: "¿Adaptas tus instrumentos para abordar la diversidad en el aula?",
                type: "selection",
                multiple: false,
                options: [
                    "No, porque corresponde a una actividad que realiza el equipo de integración (psicopedagogos/as, educadores/as diferenciales)",
                    "No, porque no cuento con conocimientos y/o tiempo para realizar estas adaptaciones.",
                    "No, por otra razón. Especificar",
                    "Sí (responde la siguiente pregunta)"
                ],
                openQuestion: true,
                triggerIndex: 2,
                titleOpenQuestion: "Especifique",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 21,
                title: "Si respondiste sí a la pregunta anterior, indica a las siguientes adaptaciones que haces a tus instrumentos: (Marca todas las que realices). Si respondiste no, sáltate esta pregunta.",
                type: "selection",
                multiple: true,
                options: [
                    "Adecúo aspectos formales del instrumento (agrandar la letra, incorporación de imágenes,\n" +
                    "incorporación de dibujos, separación de párrafos, reducción de preguntas, etc).",
                    "Cambio el formato del instrumento para facilitar el trabajo de las y los estudiantes (por ejemplo de modalidad oral a modalidad escrita).",
                    "Clarifico las instrucciones para que sean comprensibles para mis estudiantes.",
                    "Adecúo específicamente el instrumento para estudiantes con NEE.",
                    "Entrego mayor tiempo para realizar la evaluación.",
                    "Incentivo el trabajo colaborativo entre estudiantes con distintas habilidades para abordar los instrumentos de evaluación.",
                    "Abordo los instrumentos incorporando una perspectiva de género",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 7,
                titleOpenQuestion: null,
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 22,
                title: "Asigna una nota de un 1.0 (nada) a un 7.0 (mucho) a los siguientes actores en relación con su interés " +
                    "en el rendimiento académico (notas) obtenido por las y los estudiantes. Desliza la barra para asignar una nota",
                type: "slider",
                step: 1,
                max: 7,
                options: [
                    {id: 0, title: "Las y los directivos"},
                    {id: 1, title: "Las y los docentes"},
                    {id: 2, title: "Equipo de apoyo a la docencia"},
                    {id: 3, title: "Las apoderadas y apoderados"},
                    {id: 4, title: "Las y los estudiantes"}
                ],
                answers: ["1.0", "1.0", "1.0", "1.0", "1.0"]
            },
            {
                id: 23,
                title: "Asigna una nota de un 1.0 (nada) a un 7.0 (mucho) a los siguientes actores en relación con su interés " +
                    "en el aprendizaje obtenido por las y los estudiantes. Desliza la barra para asignar una nota",
                type: "slider",
                step: 1,
                max: 7,
                options: [
                    {id: 0, title: "Las y los directivos"},
                    {id: 1, title: "Las y los docentes"},
                    {id: 2, title: "Equipo de apoyo a la docencia"},
                    {id: 3, title: "Las apoderadas y apoderados"},
                    {id: 4, title: "Las y los estudiantes"}
                ],
                answers: ["1.0", "1.0", "1.0", "1.0", "1.0"]

            },
            {
                id: 24,
                title: "Cuando evalúas formativamente a tus estudiantes. ¿Qué te interesa lograr? Ordena las opciones " +
                    "desde la que te parezca más a la menos importante",
                options: [
                    {id: 0, title: "Obtener información concreta del aprendizaje logrado por mis estudiantes"},
                    {id: 1, title: "Verificar en qué medida se cumplieron los objetivos de aprendizaje definidos"},
                    {
                        id: 2,
                        title: "Monitorear el avance en el proceso de aprendizaje de mis estudiantes para ajustar la enseñanza"
                    },
                    {
                        id: 3,
                        title: "Retroalimentar el aprendizaje de mis estudiantes (por ejemplo a través de instancias de discusión)"
                    },
                    {
                        id: 4,
                        title: "Promover la metacognición en mis estudiantes (por ejemplo a partir de ejercicios de autoevaluación " +
                            "del propio desempeño)"
                    },
                    {id: 5, title: "Otro"}
                ],
                type: "sorteable",
                openQuestion: true,
                triggerIndex: 5,
                titleOpenQuestion: "",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 25,
                title: "¿Qué recursos y/o actividades sueles utilizar para evaluar formativamente a tus estudiantes? (Marca " +
                    "las 3 alternativas que utilizas con más frecuencia)",
                type: "selection",
                multiple: true,
                max_selection: 3,
                options: [
                    "Actividades breves que permitan retroalimentar al grupo completo (ej: Ticket de salida, termómetro, " +
                    "preguntas de opción múltiple, etc)",
                    "Actividades prácticas (mapas conceptuales, preguntas abiertas de discusión, portafolios, etc)",
                    "Actividades que estén en diálogo con los objetivos de aprendizaje trabajados",
                    "Actividades que permitan al estudiante relacionar lo aprendido con su vida cotidiana",
                    "Actividades que fomenten la autonomía del estudiante",
                    "Actividades de análisis grupal de trabajos desarrollados por estudiantes",
                    "Recursos digitales que motiven la participación del estudiantado",
                    "Actividades de autoevaluación y coevaluación con criterios conocidos por todo/as",
                    "Actividades similares a las que enfrentarán en evaluaciones sumativas",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 9,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 26,
                title: "Después de aplicar una evaluación formativa a tus estudiantes, ¿cómo utilizas estos resultados? (Puedes marcar más de una opción si así lo deseas)",
                type: "selection",
                multiple: true,
                options: [
                    "Para planificar las siguientes unidades de aprendizaje",
                    "Para definir apoyos personalizados a estudiantes que lo requieran",
                    "Para tener un panorama general de los niveles de logro alcanzados por el grupo",
                    "Para definir acciones remediales en función de aquellos aprendizajes en que se obtuvieron menores niveles de logro",
                    "Para replantear las estrategias de enseñanza que utilicé",
                    "Para brindar retroalimentación, con foco en los aspectos logrados y por mejorar",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 6,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 27,
                title: "Cuando evalúas sumativamente, ¿Qué te interesa lograr? Ordena las opciones desde la que te parezca más a la menos importante.",
                type: "sorteable",
                multiple: true,
                options: [
                    {id: 0, title: "Evaluar mis prácticas pedagógicas"},
                    {id: 1, title: "Evaluar en qué medida se lograron los objetivos de aprendizaje propuestos"},
                    {id: 2, title: "Obtener información de los niveles de logro alcanzados por mis estudiantes"},
                    {id: 3, title: "Obtener información de las habilidades alcanzadas por mis estudiantes"},
                    {id: 4, title: "Recoger evidencia para escoger estrategias de evaluación pertinentes"},
                    {id: 5, title: "Recoger evidencia para identificar las necesidades de aprendizaje de mis estudiantes"},
                    {id: 6, title: "Cerrar de un proceso de aprendizaje"},
                    {id: 7, title: "Otro"}
                ],
                openQuestion: true,
                triggerIndex: 7,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 28,
                title: "Luego de aplicar una evaluación sumativa a tus estudiantes ¿Para qué utilizas estos resultados? (Puedes marcar más de una opción si así lo deseas)",
                type: "selection",
                multiple: true,
                options: [
                    "Para planificar las siguientes unidades de aprendizaje",
                    "Para definir apoyos personalizados a estudiantes que lo requieran",
                    "Para tener un panorama general de los niveles de logro alcanzados por el grupo",
                    "Para definir acciones remediales en función de aquellos aprendizajes en que se obtuvieron menores niveles de logro",
                    "Para replantear las estrategias de enseñanza que utilicé",
                    "Para brindar retroalimentación, con foco en los aspectos logrados y por mejorar",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 6,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 29,
                title: "¿Qué recursos y/o actividades sueles utilizar para evaluar sumativamente a tus estudiantes? (Marca las 3 alternativas que utilizas con más frecuencia)",
                type: "selection",
                multiple: true,
                max_selection: 3,
                options: [
                    "Pruebas escritas (como exámenes finales, cuestionarios, etc.)",
                    "Uso de pruebas estandarizadas o externas",
                    "Actividades que involucren trabajos y/o proyectos de investigación individuales o grupales",
                    "Presentaciones orales (disertaciones, teatrales, musicales, exposiciones)",
                    "Actividades basadas en observación directa del desempeño de los estudiantes (laboratorios, demostraciones, actividades prácticas)",
                    "Evaluación de ensayos escritos (ensayos, informes, informes de laboratorio, etc.)",
                    "Trabajo manual de los estudiantes (maquetas, afiches, fanzine, cómics)",
                    "Portafolios o carpetas de evidencias de los estudiantes",
                    "Actividades similares a las que enfrentarán en evaluaciones formativas",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 9,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null

            },
            {
                id: 30,
                title: " De acuerdo con tu experiencia ¿Qué es para ti una buena retroalimentación? Prioriza las " +
                    "alternativas que te parezcan más relevantes, arrastrándolas desde la que te parezca más importante " +
                    "hasta la menos importante.",
                options: [
                    {
                        id: 0,
                        title: "Es información que se entrega al estudiante, tanto de sus logros como de aspectos a mejorar, en base a criterios"
                    },
                    {
                        id: 1,
                        title: "Es información que se entrega al estudiante sobre su nivel de logro, con el fin de que la use para alcanzar objetivos de aprendizaje"
                    },
                    {
                        id: 2,
                        title: "Es información que se entrega al estudiante, con el fin de ayudarle a definir estrategias para mejorar"
                    },
                    {
                        id: 3,
                        title: "Es información que analiza las respuestas de un instrumento, con foco en las preguntas en que se obtuvieron menores niveles de logro"
                    },
                    {
                        id: 4,
                        title: "Es una interacción con los/las estudiantes, en donde se corrigen los errores sobre un contenido"
                    },
                    {
                        id: 5,
                        title: "Es una instancia que proporciona información al docente o la docente sobre la pertinencia de la evaluación que se diseñó"
                    },
                    {
                        id: 6,
                        title: "Es una forma de utilizar el error para promover la metacognición en mis estudiantes"
                    }
                ],
                type: "sorteable",
                openQuestion: false,
                triggerIndex: null,
                titleOpenQuestion: "",
                answers: [],
                answerOpenQuestion: null
            },
            {
                id: 31,
                title: "¿Cuáles son los principales conocimientos y/o habilidades que te gustaría fortalecer para evaluar de mejor forma a tus estudiantes?",
                type: "selection",
                multiple: true,
                options: [
                    "Diversificar instrumentos (rúbricas, listas de cotejo, pautas, otros)",
                    "Resguardar que el instrumento mida lo que pretende medir",
                    "Diversificar las formas de evaluar (metodologías)",
                    "Diseñar situaciones evaluativas significativas para mis estudiantes",
                    "Crear instrumentos que aborden la dimensión socioemocional de mis estudiantes",
                    "Crear instrumentos que permitan detectar a tiempo necesidades de mis estudiantes",
                    "Crear instrumentos que evidencien las habilidades adquiridas por mis estudiantes",
                    "Conocer estrategias para retroalimentar a mis estudiantes",
                    "Otro"
                ],
                openQuestion: true,
                triggerIndex: 9,
                titleOpenQuestion: "¿Cuál?",
                answers: [],
                answerOpenQuestion: null
            }
        ]
    },
    {
        step: 6,
        questions: [
            {
                id: 32,
                title: "Si tienes algún comentario adicional, puedes dejarlo consignado aquí:",
                options: null,
                type: "text",
                answers: []
            }
        ]
    }


];

export function preguntas_data() {
    return preguntas;
}
