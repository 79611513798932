<template>
  <div class="wizard hstack gap-2 mb-4" v-if="step >= 2">
    <div class="vstack gap-1">
      <div class="rounded-3 wizard-line" :class="[step === 2 ? 'wizard-line-enable' : 'wizard-line-disable']"></div>
      <small class="text-center">Información personal</small>
    </div>

    <div class="vstack gap-1">
      <div class="rounded-3 wizard-line" :class="[step === 3 ? 'wizard-line-enable' : 'wizard-line-disable']"></div>
      <small class="text-center">Formación y Trayectoria laboral</small>
    </div>

    <div class="vstack gap-1">
      <div class="rounded-3 wizard-line" :class="[step === 4 ? 'wizard-line-enable' : 'wizard-line-disable']"></div>
      <small class="text-center">Creencias sobre la evaluación</small>
    </div>

    <div class="vstack gap-1">
      <div class="rounded-3 wizard-line" :class="[step === 5 ? 'wizard-line-enable' : 'wizard-line-disable']"></div>
      <small class="text-center">Decisiones y prácticas evaluativas en el aula</small>
    </div>

    <div class="vstack gap-1 d-none">
      <div class="rounded-3 wizard-line" :class="[step === 6 ? 'wizard-line-enable' : 'wizard-line-disable']"></div>
      <small class="text-center">Expectativas</small>
    </div>
  </div>

  <notifications
      :duration="5000"
      :width="350"
      classes="bg-trayectics"
      animation-name="v-fade-left"
      position="top center"
  />


  <div class="mt-2 mb-4 card-trayectics p-3 p-lg-5 bg-white rounded-4 position-relative">
    <div v-if="step === 0">

      <h6 class="mb-3 ps-3"><span class="fw-semibold">Estimado/a participante:</span></h6>
      <p class="ps-3">
        La encuesta que se presenta a continuación, tiene como propósito <strong>recoger evidencia respecto a los
        procesos
        evaluativos que se desarrollan en su establecimiento</strong>, de tal manera que dicha información permita
        contribuir a procesos de mejora continua pertinentes a la realidad y necesidades de su comunidad educativa.
      </p>
      <p class="ps-3">
        La encuesta se compone de <strong>31</strong> preguntas que abordan <strong>(3)</strong> dimensiones:
      </p>

      <div class="mb-3 ps-3">
        <ul>
          <li class="mb-1">Formación y trayectoria laboral</li>
          <li class="mb-1">Creencias sobre la evaluación</li>
          <li class="mb-1">Decisiones y prácticas evaluativas en el aula</li>
        </ul>
      </div>

      <div class="mb-3 ps-3 hstack gap-3">
        <img src="/user_check.svg" alt="Usuario">
        <span>Te pedimos puedas responder esta encuesta en un espacio apropiado donde <span class="fw-semibold">puedas concentrarte y reflexionar</span> en torno a las preguntas que se sugieren.</span>
      </div>

      <div class="mb-3 ps-3 hstack gap-3">
        <img src="/clock.svg" alt="Reloj">
        <span>Esta encuesta toma aproximadamente entre <strong>20 y 30 min.</strong></span>
      </div>

      <div class="text-center">
        <div class="mt-5">
          <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="start">Comenzar</button>
        </div>
      </div>
    </div>

    <div v-if="step === 1">
      <h5 class="mb-4 text-center"><strong>Trayectics protege y respeta tu privacidad.</strong></h5>
      <hr>
      <h6 class="mb-5 text-center"><strong>Consentimiento Informado</strong></h6>

      <p>
        <strong>Título de la investigación:</strong> Encuesta Nacional sobre Procesos Evaluativos Contextualizados.
      </p>

      <p>
        <strong>El objetivo de esta investigación:</strong> Realizar un diagnóstico de los procesos evaluativos en escuelas chilenas en el contexto de implementación del decreto 67.
      </p>
      <p>
        Usted ha sido invitado/invitada, en calidad de docente de una escuela chilena, para participar en una investigación que busca profundizar en las creencias y prácticas evaluativas de los equipos docentes a nivel nacional.
        Todo esto, en el contexto de la implementación del decreto 67.
      </p>
      <p class="mb-1"><strong>Su participación consistirá en:</strong></p>
      <ul>
        <li class="mb-1">responder un cuestionario online</li>
      </ul>
      <p>
        Antes de proceder encontrará un botón indicando "acepto participar" o "no acepto participar". Al marcar en el botón "Acepto participar", usted da su consentimiento para que la información recopilada se utilice únicamente con fines académicos y/o de investigación.
      </p>
      <p>
        Por lo tanto, antes de comenzar, y hacer click en "acepto participar" lea cuidadosamente el texto a continuación.
      </p>
      <ul>
        <li class="mb-1"><strong>Su participación es absolutamente voluntaria, por lo que no hay obligación alguna de participar</strong> en este estudio. Más aún puede dejar de participar en cualquier momento, no estando obligado a responder pregunta alguna.</li>
        <li>La información proporcionada por usted se utilizará solo para el propósito de esta investigación, y las publicaciones que resulten de ella.</li>
        <li>Los datos podrán ser utilizados en investigaciones futuras que sigan la misma línea investigativa de este estudio.</li>
        <li>Los datos serán almacenados por un período de 5 años, una vez finalizada la investigación, siendo la fecha de término estimada diciembre de 2028.</li>
        <li>Los datos obtenidos en esta investigación y los datos individuales son estrictamente <strong>confidenciales</strong> y <strong>anónimos</strong>. No se divulgará información individual alguna en las presentaciones y publicaciones de los resultados.</li>
        <li>
          Toda la información obtenida <strong>será protegida a través de claves de acceso</strong>, permitiendo así solo que el equipo que trabaja en el proyecto pueda revisarlo. Toda la información obtenida será destruida luego de 5 años de finalizado el proyecto.
        </li>
        <li>
          No hay beneficios directos asociados a la participación en el estudio. Sin embargo, la escuela recibirá un informe con los resultados agregados. Adicionalmente, los sostenedores con más de 5 establecimientos recibirán un informe consolidado, y podrán contar con una devolución de los resultados en formato taller para sus equipos directivos.
        </li>
        <li>
          <strong>No hay riesgos</strong> asociados a su participación. Sin embargo, usted no está obligado a continuar si se siente incómodo.
        </li>
      </ul>

      <p>
        Si tiene preguntas acerca de esta investigación contactar a los investigadores responsables del estudio: Macarena Salas Aguayo, email de contacto: <a href="mailto:msalas1@uc.cl">msalas1@uc.cl</a> y teléfono de contacto: +56 9 8821 8970 (investigadora principal), Dany López González, email de contacto: <a href="mailto:dxlopez@uc.cl">dxlopez@uc.cl</a> (co-investigador) y Lorena Medina Morales, email de contacto: <a href="mailto:lmedinam@uc.cl">lmedinam@uc.cl</a> (co-investigadora).
      </p>

      <p class="fw-bold">
        Considerando lo anterior ¿Está Ud. dispuesto/a a completar un cuestionario online en un horario y fecha convenido con usted que le presentaré a continuación? Si es así, por favor haga clic en el botón respectivo:
      </p>

      <div class="form-check mb-3 mt-5">
        <input class="form-check-input" id="checkbox-privacy" type="checkbox" v-model="privacyCheck">
        <label class="form-check-label" for="checkbox-privacy">
          Consiento que mi información sea utilizada para estos fines.
        </label>
      </div>
      <div class="mt-5"></div>
      <div class="">
        <div class="d-flex flex-row-reverse gap-3">
          <button class="btn btn-warning btn-trayectics-secondary" type="button" @click.prevent="step = 0">Ir atrás
          </button>
          <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="step = 2"
                  :disabled="!privacyCheck">Continuar
          </button>
        </div>
      </div>
    </div>

    <Transition>
      <div v-if="step === 2">
        <ContactForm @start-question="handleStartQuestion" @back-question="handleBack"/>
      </div>
    </Transition>

    <Transition>
      <div v-if="step >= 3 && step <= 6">
        <div class="d-flex flex-column mb-3 gap-4">

          <div>
            <h6 class="fw-semibold mb-4" v-if="step < 6">Pregunta {{ questionIndex + 1 }} de {{ questionQty }}</h6>

            <div class="hstack gap-3 border-2"><h6 class="mb-4" v-html="question.title"></h6></div>

            <div class="" v-if="question.type === 'selection' && question.multiple">
              <div class="row p-1">
                <div class="col-12 mb-2" v-for="(option, indexOption) in question.options"
                     :key="option">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                           :value="indexOption"
                           v-model="question.answers">
                    <label class="form-check-label">{{ option }}</label>

                    <div class="mb-3"
                         v-if="question.openQuestion && question.triggerIndex === -1 && question.answers.includes(indexOption)">
                      <label class="form-label">{{ question.titleOpenQuestion }}</label>
                      <input type="text" class="form-control" placeholder=""
                             v-model="question.answerOpenQuestion[indexOption]">
                    </div>
                  </div>
                </div>
                <div class="bg-danger text-light p-2 rounded-3"
                     v-if="showMaxValidation && question.max_selection !== undefined">
                  No está permitido seleccionar más de {{ question.max_selection }} opciones.
                </div>
                <div class="mb-3" v-if="question.openQuestion && question.answers.includes(question.triggerIndex)">
                  <label class="form-label">{{ question.titleOpenQuestion }}</label>
                  <input type="text" class="form-control" placeholder="" v-model="question.answerOpenQuestion">
                </div>
              </div>
            </div>

            <div class="vstack gap-2 p-1" v-if="question.type === 'text_multiple'">
              <div class="mb-3" v-for="(title, index) in question.titles" :key="title">
                <label class="form-label">{{ title }}</label>
                <input type="text" class="form-control" placeholder="" v-model="question.answers[index]">
              </div>
            </div>

            <div class="vstack gap-1 p-1" v-if="question.type === 'selection' && !question.multiple">
              <div class="form-check" v-for="(option, indexOption) in question.options" :key="option">
                <input class="form-check-input" type="radio" :value="indexOption" v-model="question.answers">
                <label class="form-check-label">{{ option }}</label>
              </div>

              <div class="mb-3" v-if="question.openQuestion && question.answers === question.triggerIndex">
                <label class="form-label">{{ question.titleOpenQuestion }}</label>
                <input type="text" class="form-control" placeholder="" v-model="question.answerOpenQuestion">
              </div>
            </div>

            <div class="mb-3 p-1" v-if="question.type === 'text'">
              <textarea class="form-control bg-white" rows="4" v-model="question.answers"></textarea>
            </div>

            <div class="mb-3" v-if="question.type === 'sorteable' ">
              <div class="hstack gap-2">
                <img src="/importancia.svg" alt="Importancia">
                <Sortable
                    :list="question.options"
                    item-key="id"
                    tag="div"
                    @end="handleChangeSorteable">
                  <template #item="
/* eslint-disable vue/no-unused-vars */ {element, indexSorteable}">
                    <div class="draggable mb-3 p-2" :key="element.id">
                      <img src="/drag_icon.png" style="width: 10px" class="me-2"> {{ element.title }}
                    </div>
                  </template>
                </Sortable>
              </div>

              <div class="mb-3 mt-2" v-if="question.openQuestion">
                <label class="form-label">{{ question.titleOpenQuestion }}</label>
                <input type="text" class="form-control"
                       placeholder="Escribe la opción que quieras incluir aquí"
                       v-model="question.answerOpenQuestion">
              </div>
            </div>

            <div class="mb-3 overflow-auto h-400px" v-if="question.type === 'rubric'">
              <table class="table">
                <thead class="bg-gray">
                <tr>
                  <th></th>
                  <th scope="col" class="text-center" v-for="(column) in question.options_column" :key="column">
                    {{ column }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(option, indexOption) in question.options" :key="option">
                  <th scope="row" class="fw-normal">{{ option.title }}</th>

                  <td v-for="(column, indexColumn) in question.options_column" :key="column" class="text-center">
                    <div class="form-check text-center">
                      <input class="form-check-input ms-4" type="radio" :value="indexColumn"
                             v-model="question.answers[option.id]">
                      <label class="form-check-label text-center"></label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>

            <div class="mb-3" v-if="question.type === 'slider'">
              <div class="row">
                <div class="col-md-4 offset-md-1 bg-gray mb-3 p-3" v-for="(option, index) in question.options"
                     :key="option.id">
                  <div>
                    <label class="form-label">{{ option.title }} <span
                        v-if="question.answers[index] >= 1">({{ question.answers[index] }})</span><span
                        v-else>(0)</span></label>
                    <input type="range" class="form-range" min="1" max="7" step="0.5" v-model="question.answers[index]">
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="text-center">
          <div class="d-flex flex-row-reverse gap-3 mt-3">
            <button class="btn btn-warning btn-trayectics-secondary" type="button"
                    @click.prevent="handleBackQuestion">
              Ir atrás
            </button>
            <button class="btn btn-primary btn-trayectics" type="button"
                    @click.prevent="handleNextQuestion"
                    :disabled="isProcessFinish || (showMaxValidation && question.max_selection !== undefined)">Continuar
            </button>
          </div>
        </div>
      </div>
    </Transition>

    <div v-if="step === 7">
      <p>
        De vez en cuando, nos gustaría ponernos en contacto contigo para compartir contenidos educativos y
        actividades que pueden ser de tu interés. Puedes darte de baja de estas comunicaciones en cualquier momento.
        ¿Aceptas que nos comuniquemos contigo para este fin? Marca la casilla a continuación
      </p>
      <div v-if="isProcessFinish">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>
      <div class="hstack gap-2" v-if="!isProcessFinish">
        <button class="btn btn-primary btn-trayectics" type="button"
                @click.prevent="handleNextQuestion">
          Si
        </button>
        <button class="btn btn-primary btn-trayectics-secondary" type="button"
                @click.prevent="handleNextQuestion">
          No
        </button>
      </div>

    </div>

    <div v-if="step === 8">
      <div class="text-center mb-3 gap-4">
        <h4 class="mt-5">¡Muchísimas gracias!</h4>
        <p>Los resultados de esta encuesta serán socializados en el primer taller que realizaremos durante el mes de mayo.</p>
        <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="handleFinish">Volver al inicio</button>
      </div>
    </div>


  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import {Sortable} from "sortablejs-vue3";
import {defineAsyncComponent} from "vue";
import {preguntas_data} from "@/utils/preguntas";

export default {
  name: 'App',
  components: {
    ContactForm,
    Sortable,
    // eslint-disable-next-line vue/no-unused-components
    MultipleOption: defineAsyncComponent(() =>
        import('@/components/MultipleOption.vue')
    ),
    // eslint-disable-next-line vue/no-unused-components
    Text: defineAsyncComponent(() =>
        import('@/components/Text.vue')
    )
  },
  mounted() {
    const step = parseInt(localStorage.getItem('step'));
    if (typeof step === 'number') {
      if (isNaN(step)) {
        this.step = 0;
      } else {
        this.step = step;
      }

    } else {
      this.step = 0;
    }

    this.loadQuestions();
  },
  methods: {
    start() {
      this.step = 1;
    },
    handleStartQuestion() {
      //console.log('#################-handleStartQuestion-#####################')
      this.step = 3;
      localStorage.setItem('items', JSON.stringify(preguntas_data()));
      this.items = JSON.parse(localStorage.getItem('items'));

      localStorage.setItem('question_index', '0');
      this.loadQuestions();
    },
    handleBack() {
      this.step = 1;
    },
    handleNextQuestion() {
      //console.log('handleNextQuestion...')
      if (this.step === 7) {
        this.isProcessFinish = true;
        const _items = JSON.parse(localStorage.getItem('items'));
        let answers = _items.map(item => item.questions.map(question => {
          if (question.type === 'sorteable') {
            return {answers: question.options.map(option => { return option.id}), a_open: question.answerOpenQuestion}
          }
          else {
            return {answers: question.answers, a_open: question.answerOpenQuestion}
          }
        }));

        const personalInfoJSON = localStorage.getItem('personal_info');

        let headers = new Headers();
        headers.append('Content-Type', 'application/json')
        const body = {answers: answers, personalInfo: personalInfoJSON, contact: this.canContact};
        const URL = "https://g74v1ouz4c.execute-api.us-east-1.amazonaws.com/default/save";

        fetch(URL, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({data: JSON.stringify(body)})
        }).then(response => {
          if (response.status == 200) {
            this.step = 8;
            localStorage.setItem('step', this.step);
          } else {
            this.$notify("Ha ocurrido un error al guardar la Encuesta. (100)");
            this.isProcessFinish = false
          }
        })
            .catch(error => {
              console.log('Error => ', error);
              this.$notify("Ha ocurrido un error al guardar la Encuesta. (101)");
              this.isProcessFinish = false
            });
        return;
      }
      if (this.questionIndex === this.questionQty - 1) {
        this.step = this.step + 1;
        localStorage.setItem('step', this.step);
        this.questionIndex = 0;
        this.questionQty = 0;
      } else {
        this.questionIndex = this.questionIndex + 1;
        //console.log('#questionIndex suma + 1 => ', this.questionIndex);
      }

      localStorage.setItem('question_index', this.questionIndex);

      this.loadQuestions()
    },
    handleBackQuestion() {
      if (this.questionIndex === 0) {
        this.step -= 1;
        this.loadQuestions()
        return;
      }
      this.questionIndex = this.questionIndex - 1;
      localStorage.setItem('question_index', this.questionIndex);
      this.loadQuestions()
    },
    loadQuestions() {
      this.items = JSON.parse(localStorage.getItem('items'));
      if (this.items === null) {
        //console.log('items is NULL')
        return;
      }
      this.questionIndex = parseInt(localStorage.getItem('question_index')) || 0;
      //console.log('####-loadQuestions-####')
      //console.log('questionIndex: ', this.questionIndex);
      const result = this.items.filter(item => item.step === this.step);
      //console.log('result: ', result);
      if (result.length > 0) {
        this.questionQty = result[0].questions.length;
        this.question = result[0].questions[this.questionIndex];
      }

      // const QTY_Q = this.items.reduce(function(a, s) {
      //   a.sum += s.questions.length;
      //   return a;
      // }, { sum: 0 });
      // this.answers = Array(QTY_Q);

    },
    moveItemInArray(array, from, to) {
      const item = array.splice(from, 1)[0];
      array.splice(to, 0, item);
    },
    handleChangeSorteable(event) {
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;
      //console.log('oldIndex: ' + oldIndex + ', newIndex: ' + newIndex);

      const itemIndex = this.items.findIndex(obj => {
        return obj.step === this.step;
      });

      let qs = this.items[itemIndex].questions[this.questionIndex];
      //console.log('QS', qs);
      this.moveItemInArray(qs.options, oldIndex, newIndex)
      //console.log('QS', qs);
    },
    handleFinish() {
      localStorage.clear();
      location.reload();
    }
  },
  data() {
    return {
      step: 0,
      questionIndex: 0,
      questionQty: 0,
      privacyCheck: false,
      canContact: false,
      personalInfo: {
        name: null,
        last_name: null,
        age: null,
        email: null,
        gender: null,
        establishment: null,
        city: null,
        commune: null,
        area: null
      },
      items: preguntas_data(),
      question: {},
      showMaxValidation: false,
      answers: [],
      isProcessFinish: false
    }
  },
  watch: {
    question: {
      handler(newValue) {
        if (newValue.max_selection !== undefined) {
          if (newValue.answers.length > newValue.max_selection) {
            this.showMaxValidation = true
          } else {
            this.showMaxValidation = false
          }
        }

        const itemIndex = this.items.findIndex(obj => {
          return obj.step === this.step;
        })
        const indexQuestion = this.items[itemIndex].questions.findIndex(q => {
          return q.id === newValue.id;
        })
        this.items[itemIndex].questions[indexQuestion].answers = newValue.answers;

        localStorage.setItem('items', JSON.stringify(this.items));
      },
      deep: true
    }
  }
}
</script>

<style>

</style>