<template>
  <div class="col">
    <h5 class="mb-3 fw-semibold">Información personal</h5>
    <!--    <code>{{ personalInfo }}</code>-->

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Nombres</label>
        <input type="text" class="form-control"
               v-model="personalInfo.name"
               :class="{ 'is-invalid': ((personalInfo.name == null || personalInfo.name === '') && showValidation) }">
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Apellidos</label>
        <input type="text" class="form-control"
               v-model="personalInfo.last_name"
               :class="{ 'is-invalid': ( (personalInfo.last_name == null || personalInfo.last_name === '') && showValidation) }">
      </div>

    </div>

    <div class="row mb-3">

      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Edad</label>
        <input type="number" class="form-control"
               min="1" max="100"
               step="1"
               v-model.number="personalInfo.age"
               :class="{ 'is-invalid': ((personalInfo.age == null || personalInfo.age === '') && showValidation) }">
      </div>

      <div class="col-12 col-md-6">
        <label class="form-label">Región en donde se ubica el establecimiento</label>
        <select class="form-select" aria-label="Región en donde se ubica el establecimiento"
                v-model="personalInfo.city"
                @change="handleCityChange"
                :class="{ 'is-invalid': (personalInfo.city == null && showValidation) }">
          <option selected disabled></option>
          <option v-for="region in regions" :key="region.id" :value="region.number">{{ region.name }}</option>
        </select>
      </div>

    </div>

    <div class="row mb-3">

      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Dependencia del establecimiento donde trabajas</label>
        <select class="form-select" aria-label="Dependencia del establecimiento donde trabajas"
                v-model="personalInfo.establishment"
                :class="{ 'is-invalid': ((personalInfo.establishment == null || personalInfo.establishment === '') && showValidation) }">
          <option selected disabled value="-1"></option>
          <option value="1">Municipal</option>
          <option value="2">Servicio Local de Educación</option>
          <option value="3">Particular Subvencionado</option>
          <option value="4">Administración Delegada</option>
          <option value="5">Particular</option>
        </select>
      </div>

      <div class="col-12 col-md-6">
        <label class="form-label">Comuna en donde se ubica el establecimiento</label>
        <select class="form-select" aria-label="Comuna en donde se ubica el establecimiento"
                v-model="personalInfo.commune"
                @change="handleCommuneChange"
                :class="{ 'is-invalid': (personalInfo.commune == null && showValidation) }">
          <option v-for="commune in communes" :key="commune.id" :value="commune.id">{{ commune.name }}</option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Área donde se ubica tu establecimiento</label>
        <select class="form-select" aria-label="Área donde se ubica tu establecimiento"
                v-model="personalInfo.area"
                :class="{ 'is-invalid': (personalInfo.area == null && showValidation) }">
          <option selected disabled value="-1"></option>
          <option value="1">Rural</option>
          <option value="2">Urbano</option>
        </select>
      </div>

      <div class="col-12 col-md-6 mb-3 position-relative">
        <label class="form-label">Selecciona Colegio</label>
        <input type="text"
               class="form-control"
               placeholder="Buscar aquí..."
               v-model="personalInfo.school"
               @input="searchSchools"
               @focus="$event.target.select()"
               :class="{ 'is-invalid': ( (personalInfo.school == null || personalInfo.school === '') && showValidation) }">
        <div v-if="showResults" class="list-group position-absolute start-0 p-0 h-auto overflow-auto shadow"
             style="bottom: 60% !important;z-index: 10000;">
          <button type="button" class="list-group-item list-group-item-action" v-for="school in schoolsFiltered"
                  :key="school.name"
                  @click="personalInfo.school=school.name;this.showResults = false;">
            {{ school.name }}
          </button>
        </div>
      </div>

    </div>

    <div class="row mb-3" @click="this.showResults=false">
      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Género</label>
        <select class="form-select" aria-label="Género"
                v-model="personalInfo.gender"
                :class="{ 'is-invalid': (personalInfo.gender == null && showValidation) }">
          <option selected disabled value="-1"></option>
          <option value="0">Femenino</option>
          <option value="1">Masculino</option>
          <option value="2">Otro</option>
          <option value="3">Prefiero no responder</option>
        </select>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="form-label">Correo Electrónico</label>
        <input type="email" class="form-control"
               v-model="personalInfo.email"
               :class="{ 'is-invalid': ((personalInfo.email == null || personalInfo.email === '') && showValidation) }">
      </div>
    </div>

    <div class="text-center">
      <div class="d-flex flex-row-reverse gap-3">
        <button class="btn btn-warning btn-trayectics-secondary" type="button"
                @click.prevent="this.$emit('backQuestion')">Ir atrás
        </button>
        <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="handleNext">Continuar</button>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "ContactForm",
  emits: ["startQuestion", "backQuestion"],
  data() {
    return {
      showValidation: false,
      searchSchoolQuery: '',
      showResults: false,
      personalInfo: {
        name: null,
        last_name: null,
        age: null,
        email: null,
        gender: null,
        establishment: null,
        city: null,
        commune: null,
        area: null,
        school: null
      },
      regions: [
        {
          "name": "Arica y Parinacota",
          "romanNumber": "XV",
          "number": "15",
          "id": "AP",
          "communes": [
            {"name": "Arica", "id": "arica"},
            {"name": "Camarones", "id": "camarones"},
            {"name": "General Lagos", "id": "general-lagos"},
            {"name": "Putre", "id": "putre"}
          ]
        },
        {
          "name": "Tarapacá",
          "romanNumber": "I",
          "number": "1",
          "id": "TA",
          "communes": [
            {"name": "Alto Hospicio", "id": "alto-hospicio"},
            {"name": "Camiña", "id": "camina"},
            {"name": "Colchane", "id": "colchane"},
            {"name": "Huara", "id": "huara"},
            {"name": "Iquique", "id": "iquique"},
            {"name": "Pica", "id": "pica"},
            {"name": "Pozo Almonte", "id": "pozo-almonte"}
          ]
        },
        {
          "name": "Antofagasta",
          "romanNumber": "II",
          "number": "2",
          "id": "AN",
          "communes": [
            {"name": "Antofagasta", "id": "antofagasta"},
            {"name": "Calama", "id": "calama"},
            {"name": "María Elena", "id": "maria-elena"},
            {"name": "Mejillones", "id": "mejillones"},
            {"name": "Ollagüe", "id": "ollague"},
            {"name": "San Pedro de Atacama", "id": "san-pedro-de-atacama"},
            {"name": "Sierra Gorda", "id": "sierra-gorda"},
            {"name": "Taltal", "id": "taltal"},
            {"name": "Tocopilla", "id": "tocopilla"}
          ]
        },
        {
          "name": "Atacama",
          "romanNumber": "III",
          "number": "3",
          "id": "AT",
          "communes": [
            {"name": "Alto del Carmen", "id": "alto-del-carmen"},
            {"name": "Caldera", "id": "caldera"},
            {"name": "Chañaral", "id": "chanaral"},
            {"name": "Copiapó", "id": "copiapo"},
            {"name": "Diego de Almagro", "id": "diego-de-almagro"},
            {"name": "Freirina", "id": "freirina"},
            {"name": "Huasco", "id": "huasco"},
            {"name": "Tierra Amarilla", "id": "tierra-amarilla"},
            {"name": "Vallenar", "id": "vallenar"}
          ]
        },
        {
          "name": "Coquimbo",
          "romanNumber": "IV",
          "number": "4",
          "id": "CO",
          "communes": [
            {"name": "Andacollo", "id": "andacollo"},
            {"name": "Canela", "id": "canela"},
            {"name": "Combarbalá", "id": "combarbala"},
            {"name": "Coquimbo", "id": "coquimbo"},
            {"name": "Illapel", "id": "illapel"},
            {"name": "La Higuera", "id": "la-higuera"},
            {"name": "La Serena", "id": "la-serena"},
            {"name": "Los Vilos", "id": "los-vilos"},
            {"name": "Monte Patria", "id": "monte-patria"},
            {"name": "Ovalle", "id": "ovalle"},
            {"name": "Paiguano", "id": "paiguano"},
            {"name": "Punitaqui", "id": "punitaqui"},
            {"name": "Río Hurtado", "id": "rio-hurtado"},
            {"name": "Salamanca", "id": "salamanca"},
            {"name": "Vicuña", "id": "vicuna"}
          ]
        },
        {
          "name": "Valparaíso",
          "romanNumber": "V",
          "number": "5",
          "id": "VA",
          "communes": [
            {"name": "Algarrobo", "id": "algarrobo"},
            {"name": "Cabildo", "id": "cabildo"},
            {"name": "Calera", "id": "calera"},
            {"name": "Calle Larga", "id": "calle-larga"},
            {"name": "Cartagena", "id": "cartagena"},
            {"name": "Casablanca", "id": "casablanca"},
            {"name": "Catemu", "id": "catemu"},
            {"name": "Concón", "id": "concon"},
            {"name": "El Quisco", "id": "el-quisco"},
            {"name": "El Tabo", "id": "el-tabo"},
            {"name": "Hijuelas", "id": "hijuelas"},
            {"name": "Isla de Pascua", "id": "isla-de-pascua"},
            {"name": "Juan Fernández", "id": "juan-fernandez"},
            {"name": "La Cruz", "id": "la-cruz"},
            {"name": "La Ligua", "id": "la-ligua"},
            {"name": "Limache", "id": "limache"},
            {"name": "Llaillay", "id": "llaillay"},
            {"name": "Los Andes", "id": "los-andes"},
            {"name": "Nogales", "id": "nogales"},
            {"name": "Olmué", "id": "olmue"},
            {"name": "Panquehue", "id": "panquehue"},
            {"name": "Papudo", "id": "papudo"},
            {"name": "Petorca", "id": "petorca"},
            {"name": "Puchuncaví", "id": "puchuncavi"},
            {"name": "Putaendo", "id": "putaendo"},
            {"name": "Quillota", "id": "quillota"},
            {"name": "Quilpué", "id": "quilpue"},
            {"name": "Quintero", "id": "quintero"},
            {"name": "Rinconada", "id": "rinconada"},
            {"name": "San Antonio", "id": "san-antonio"},
            {"name": "San Esteban", "id": "san-esteban"},
            {"name": "San Felipe", "id": "san-felipe"},
            {"name": "Santa María", "id": "santa-maria"},
            {"name": "Santo Domingo", "id": "santo-domingo"},
            {"name": "Valparaíso", "id": "valparaiso"},
            {"name": "Villa Alemana", "id": "villa-alemana"},
            {"name": "Viña del Mar", "id": "vina-del-mar"},
            {"name": "Zapallar", "id": "zapallar"}
          ]
        },
        {
          "name": "Metropolitana de Santiago",
          "romanNumber": "XIII",
          "number": "13",
          "id": "RM",
          "communes": [
            {"name": "Alhué", "id": "alhue"},
            {"name": "Buin", "id": "buin"},
            {"name": "Calera de Tango", "id": "calera-de-tango"},
            {"name": "Cerrillos", "id": "cerrillos"},
            {"name": "Cerro Navia", "id": "cerro-navia"},
            {"name": "Colina", "id": "colina"},
            {"name": "Conchalí", "id": "conchali"},
            {"name": "Curacaví", "id": "curacavi"},
            {"name": "El Bosque", "id": "el-bosque"},
            {"name": "El Monte", "id": "el-monte"},
            {"name": "Estación Central", "id": "estacion-central"},
            {"name": "Huechuraba", "id": "huechuraba"},
            {"name": "Independencia", "id": "independencia"},
            {"name": "Isla de Maipo", "id": "isla-de-maipo"},
            {"name": "La Cisterna", "id": "la-cisterna"},
            {"name": "La Florida", "id": "la-florida"},
            {"name": "La Granja", "id": "la-granja"},
            {"name": "La Pintana", "id": "la-pintana"},
            {"name": "La Reina", "id": "la-reina"},
            {"name": "Lampa", "id": "lampa"},
            {"name": "Las Condes", "id": "las-condes"},
            {"name": "Lo Barnechea", "id": "lo-barnechea"},
            {"name": "Lo Espejo", "id": "lo-espejo"},
            {"name": "Lo Prado", "id": "lo-prado"},
            {"name": "Macul", "id": "macul"},
            {"name": "Maipú", "id": "maipu"},
            {"name": "María Pinto", "id": "maria-pinto"},
            {"name": "Melipilla", "id": "melipilla"},
            {"name": "Ñuñoa", "id": "nunoa"},
            {"name": "Padre Hurtado", "id": "padre-hurtado"},
            {"name": "Paine", "id": "paine"},
            {"name": "Pedro Aguirre Cerda", "id": "pedro-aguirre-cerda"},
            {"name": "Peñaflor", "id": "penaflor"},
            {"name": "Peñalolén", "id": "penalolen"},
            {"name": "Pirque", "id": "pirque"},
            {"name": "Providencia", "id": "providencia"},
            {"name": "Pudahuel", "id": "pudahuel"},
            {"name": "Puente Alto", "id": "puente-alto"},
            {"name": "Quilicura", "id": "quilicura"},
            {"name": "Quinta Normal", "id": "quinta-normal"},
            {"name": "Recoleta", "id": "recoleta"},
            {"name": "Renca", "id": "renca"},
            {"name": "San Bernardo", "id": "san-bernardo"},
            {"name": "San Joaquín", "id": "san-joaquin"},
            {"name": "San José de Maipo", "id": "san-jose-de-maipo"},
            {"name": "San Miguel", "id": "san-miguel"},
            {"name": "San Pedro", "id": "san-pedro"},
            {"name": "San Ramón", "id": "san-ramon"},
            {"name": "Santiago", "id": "santiago"},
            {"name": "Talagante", "id": "talagante"},
            {"name": "Tiltil", "id": "tiltil"},
            {"name": "Vitacura", "id": "vitacura"}
          ]
        },
        {
          "name": "Libertador Gral. Bernardo O’Higgins",
          "romanNumber": "VI",
          "number": "6",
          "id": "LI",
          "communes": [
            {"name": "Chimbarongo", "id": "chimbarongo"},
            {"name": "Chépica", "id": "chepica"},
            {"name": "Codegua", "id": "codegua"},
            {"name": "Coinco", "id": "coinco"},
            {"name": "Coltauco", "id": "coltauco"},
            {"name": "Doñihue", "id": "donihue"},
            {"name": "Graneros", "id": "graneros"},
            {"name": "La Estrella", "id": "la-estrella"},
            {"name": "Las Cabras", "id": "las-cabras"},
            {"name": "Litueche", "id": "litueche"},
            {"name": "Lolol", "id": "lolol"},
            {"name": "Machalí", "id": "machali"},
            {"name": "Malloa", "id": "malloa"},
            {"name": "Marchihue", "id": "marchihue"},
            {"name": "Nancagua", "id": "nancagua"},
            {"name": "Navidad", "id": "navidad"},
            {"name": "Olivar", "id": "olivar"},
            {"name": "Mostazal", "id": "mostazal"},
            {"name": "Palmilla", "id": "palmilla"},
            {"name": "Paredones", "id": "paredones"},
            {"name": "Peralillo", "id": "peralillo"},
            {"name": "Peumo", "id": "peumo"},
            {"name": "Pichidegua", "id": "pichidegua"},
            {"name": "Pichilemu", "id": "pichilemu"},
            {"name": "Placilla", "id": "placilla"},
            {"name": "Pumanque", "id": "pumanque"},
            {"name": "Quinta de Tilcoco", "id": "quinta-de-tilcoco"},
            {"name": "Rancagua", "id": "rancagua"},
            {"name": "Rengo", "id": "rengo"},
            {"name": "Requínoa", "id": "requinoa"},
            {"name": "San Fernando", "id": "san-fernando"},
            {"name": "San Vicente", "id": "san-vicente"},
            {"name": "Santa Cruz", "id": "santa-cruz"}
          ]
        },
        {
          "name": "Maule",
          "romanNumber": "VII",
          "number": "7",
          "id": "ML",
          "communes": [
            {"name": "Cauquenes", "id": "cauquenes"},
            {"name": "Chanco", "id": "chanco"},
            {"name": "Colbún", "id": "colbun"},
            {"name": "Constitución", "id": "constitucion"},
            {"name": "Curepto", "id": "curepto"},
            {"name": "Curicó", "id": "curico"},
            {"name": "Empedrado", "id": "empedrado"},
            {"name": "Hualañé", "id": "hualane"},
            {"name": "Licantén", "id": "licanten"},
            {"name": "Linares", "id": "linares"},
            {"name": "Longaví", "id": "longavi"},
            {"name": "Maule", "id": "maule"},
            {"name": "Molina", "id": "molina"},
            {"name": "Parral", "id": "parral"},
            {"name": "Pelarco", "id": "pelarco"},
            {"name": "Pelluhue", "id": "pelluhue"},
            {"name": "Pencahue", "id": "pencahue"},
            {"name": "Rauco", "id": "rauco"},
            {"name": "Retiro", "id": "retiro"},
            {"name": "Romeral", "id": "romeral"},
            {"name": "Río Claro", "id": "rio-claro"},
            {"name": "Sagrada Familia", "id": "sagrada-familia"},
            {"name": "San Clemente", "id": "san-clemente"},
            {"name": "San Javier de Loncomilla", "id": "san-javier-de-loncomilla"},
            {"name": "San Rafael", "id": "san-rafael"},
            {"name": "Talca", "id": "talca"},
            {"name": "Teno", "id": "teno"},
            {"name": "Vichuquén", "id": "vichuquen"},
            {"name": "Villa Alegre", "id": "villa-alegre"},
            {"name": "Yerbas Buenas", "id": "yerbas-buenas"}
          ]
        },
        {
          "name": "Ñuble",
          "romanNumber": "XVI",
          "number": "16",
          "id": "NB",
          "communes": [
            {"name": "Bulnes", "id": "bulnes"},
            {"name": "Chillán Viejo", "id": "chillan-viejo"},
            {"name": "Chillán", "id": "chillan"},
            {"name": "Cobquecura", "id": "cobquecura"},
            {"name": "Coelemu", "id": "coelemu"},
            {"name": "Coihueco", "id": "coihueco"},
            {"name": "El Carmen", "id": "el-carmen"},
            {"name": "Ninhue", "id": "ninhue"},
            {"name": "Ñiquén", "id": "niquen"},
            {"name": "Pemuco", "id": "pemuco"},
            {"name": "Pinto", "id": "pinto"},
            {"name": "Portezuelo", "id": "portezuelo"},
            {"name": "Quillón", "id": "quillon"},
            {"name": "Quirihue", "id": "quirihue"},
            {"name": "Ránquil", "id": "ranquil"},
            {"name": "San Carlos", "id": "san-carlos"},
            {"name": "San Fabián", "id": "san-fabian"},
            {"name": "San Ignacio", "id": "san-ignacio"},
            {"name": "San Nicolás", "id": "san-nicolas"},
            {"name": "Treguaco", "id": "treguaco"},
            {"name": "Yungay", "id": "yungay"}
          ]
        },
        {
          "name": "Biobío",
          "romanNumber": "VIII",
          "number": "8",
          "id": "BI",
          "communes": [
            {"name": "Alto Biobío", "id": "alto-biobio"},
            {"name": "Antuco", "id": "antuco"},
            {"name": "Arauco", "id": "arauco"},
            {"name": "Cabrero", "id": "cabrero"},
            {"name": "Cañete", "id": "canete"},
            {"name": "Chiguayante", "id": "chiguayante"},
            {"name": "Concepción", "id": "concepcion"},
            {"name": "Contulmo", "id": "contulmo"},
            {"name": "Coronel", "id": "coronel"},
            {"name": "Curanilahue", "id": "curanilahue"},
            {"name": "Florida", "id": "florida"},
            {"name": "Hualpén", "id": "hualpen"},
            {"name": "Hualqui", "id": "hualqui"},
            {"name": "Laja", "id": "laja"},
            {"name": "Lebu", "id": "lebu"},
            {"name": "Los Álamos", "id": "los-alamos"},
            {"name": "Los Ángeles", "id": "los-angeles"},
            {"name": "Lota", "id": "lota"},
            {"name": "Mulchén", "id": "mulchen"},
            {"name": "Nacimiento", "id": "nacimiento"},
            {"name": "Negrete", "id": "negrete"},
            {"name": "Penco", "id": "penco"},
            {"name": "Quilaco", "id": "quilaco"},
            {"name": "Quilleco", "id": "quilleco"},
            {"name": "San Pedro de la Paz", "id": "san-pedro-de-la-paz"},
            {"name": "San Rosendo", "id": "san-rosendo"},
            {"name": "Santa Bárbara", "id": "santa-barbara"},
            {"name": "Santa Juana", "id": "santa-juana"},
            {"name": "Talcahuano", "id": "talcahuano"},
            {"name": "Tirúa", "id": "tirua"},
            {"name": "Tomé", "id": "tome"},
            {"name": "Tucapel", "id": "tucapel"},
            {"name": "Yumbel", "id": "yumbel"}
          ]
        },
        {
          "name": "Araucanía",
          "romanNumber": "IX",
          "number": "9",
          "id": "AR",
          "communes": [
            {"name": "Angol", "id": "angol"},
            {"name": "Carahue", "id": "carahue"},
            {"name": "Cholchol", "id": "cholchol"},
            {"name": "Collipulli", "id": "collipulli"},
            {"name": "Cunco", "id": "cunco"},
            {"name": "Curacautín", "id": "curacautin"},
            {"name": "Curarrehue", "id": "curarrehue"},
            {"name": "Ercilla", "id": "ercilla"},
            {"name": "Freire", "id": "freire"},
            {"name": "Galvarino", "id": "galvarino"},
            {"name": "Gorbea", "id": "gorbea"},
            {"name": "Lautaro", "id": "lautaro"},
            {"name": "Loncoche", "id": "loncoche"},
            {"name": "Lonquimay", "id": "lonquimay"},
            {"name": "Los Sauces", "id": "los-sauces"},
            {"name": "Lumaco", "id": "lumaco"},
            {"name": "Melipeuco", "id": "melipeuco"},
            {"name": "Nueva Imperial", "id": "nueva-imperial"},
            {"name": "Padre las Casas", "id": "padre-las-casas"},
            {"name": "Perquenco", "id": "perquenco"},
            {"name": "Pitrufquén", "id": "pitrufquen"},
            {"name": "Pucón", "id": "pucon"},
            {"name": "Purén", "id": "puren"},
            {"name": "Renaico", "id": "renaico"},
            {"name": "Saavedra", "id": "saavedra"},
            {"name": "Temuco", "id": "temuco"},
            {"name": "Teodoro Schmidt", "id": "teodoro-schmidt"},
            {"name": "Toltén", "id": "tolten"},
            {"name": "Traiguén", "id": "traiguen"},
            {"name": "Victoria", "id": "victoria"},
            {"name": "Vilcún", "id": "vilcun"},
            {"name": "Villarrica", "id": "villarrica"}
          ]
        },
        {
          "name": "Los Ríos",
          "romanNumber": "XIV",
          "number": "14",
          "id": "LR",
          "communes": [
            {"name": "Corral", "id": "corral"},
            {"name": "Futrono", "id": "futrono"},
            {"name": "La Unión", "id": "la-union"},
            {"name": "Lago Ranco", "id": "lago-ranco"},
            {"name": "Lanco", "id": "lanco"},
            {"name": "Los Lagos", "id": "los-lagos"},
            {"name": "Mariquina", "id": "mariquina"},
            {"name": "Máfil", "id": "mafil"},
            {"name": "Paillaco", "id": "paillaco"},
            {"name": "Panguipulli", "id": "panguipulli"},
            {"name": "Río Bueno", "id": "rio-bueno"},
            {"name": "Valdivia", "id": "valdivia"}
          ]
        },
        {
          "name": "Los Lagos",
          "romanNumber": "X",
          "number": "10",
          "id": "LL",
          "communes": [
            {"name": "Ancud", "id": "ancud"},
            {"name": "Calbuco", "id": "calbuco"},
            {"name": "Castro", "id": "castro"},
            {"name": "Chaitén", "id": "chaiten"},
            {"name": "Chonchi", "id": "chonchi"},
            {"name": "Cochamó", "id": "cochamo"},
            {"name": "Curaco de Vélez", "id": "curaco-de-velez"},
            {"name": "Dalcahue", "id": "dalcahue"},
            {"name": "Fresia", "id": "fresia"},
            {"name": "Frutillar", "id": "frutillar"},
            {"name": "Futaleufú", "id": "futaleufu"},
            {"name": "Hualaihué", "id": "hualaihue"},
            {"name": "Llanquihue", "id": "llanquihue"},
            {"name": "Los Muermos", "id": "los-muermos"},
            {"name": "Maullín", "id": "maullin"},
            {"name": "Osorno", "id": "osorno"},
            {"name": "Palena", "id": "palena"},
            {"name": "Puerto Montt", "id": "puerto-montt"},
            {"name": "Puerto Octay", "id": "puerto-octay"},
            {"name": "Puerto Varas", "id": "puerto-varas"},
            {"name": "Puqueldón", "id": "puqueldon"},
            {"name": "Purranque", "id": "purranque"},
            {"name": "Puyehue", "id": "puyehue"},
            {"name": "Queilén", "id": "queilen"},
            {"name": "Quellón", "id": "quellon"},
            {"name": "Quemchi", "id": "quemchi"},
            {"name": "Quinchao", "id": "quinchao"},
            {"name": "Río Negro", "id": "rio-negro"},
            {"name": "San Juan de la Costa", "id": "san-juan-de-la-costa"},
            {"name": "San Pablo", "id": "san-pablo"}
          ]
        },
        {
          "name": "Aisén del Gral. Carlos Ibáñez del Campo",
          "romanNumber": "XI",
          "number": "11",
          "id": "AI",
          "communes": [
            {"name": "Aysén", "id": "aysen"},
            {"name": "Chile Chico", "id": "chile-chico"},
            {"name": "Cisnes", "id": "cisnes"},
            {"name": "Cochrane", "id": "cochrane"},
            {"name": "Coyhaique", "id": "coyhaique"},
            {"name": "Guaitecas", "id": "guaitecas"},
            {"name": "Lago Verde", "id": "lago-verde"},
            {"name": "O’Higgins", "id": "o’higgins"},
            {"name": "Río Ibáñez", "id": "rio-ibanez"},
            {"name": "Tortel", "id": "tortel"}
          ]
        },
        {
          "name": "Magallanes y de la Antártica Chilena",
          "romanNumber": "XII",
          "number": "12",
          "id": "MA",
          "communes": [
            {"name": "Antártica", "id": "antartica"},
            {"name": "Cabo de Hornos", "id": "cabo-de-hornos"},
            {"name": "Laguna Blanca", "id": "laguna-blanca"},
            {"name": "Natales", "id": "natales"},
            {"name": "Porvenir", "id": "porvenir"},
            {"name": "Primavera", "id": "primavera"},
            {"name": "Punta Arenas", "id": "punta-arenas"},
            {"name": "Río Verde", "id": "rio-verde"},
            {"name": "San Gregorio", "id": "san-gregorio"},
            {"name": "Timaukel", "id": "timaukel"},
            {"name": "Torres del Paine", "id": "torres-del-paine"}
          ]
        }
      ],
      communes: [],
      schools: [],
      schoolsFiltered: []
    }
  },
  mounted() {
    const personalInfoJSON = localStorage.getItem('personal_info');
    if (personalInfoJSON !== null) {
      this.personalInfo = JSON.parse(personalInfoJSON);
      this.handleCityChange();
    }

    if (this.formIsAllValid()) {
      localStorage.setItem('step', '3');
      this.$emit('startQuestion');
    }

    if (this.personalInfo.commune !== null || this.personalInfo.commune !== '') {
      this.handleCommuneChange()
    }
  },
  methods: {
    searchSchools() {
      if (this.personalInfo.school === '') {
        this.schoolsFiltered = [];
      }

      const result = this.schools.filter(school => {
        if (school.name.toLowerCase().includes(this.personalInfo.school.toLowerCase())) {
          return school
        }
      });

      this.showResults = true;
      this.schoolsFiltered = result;
      // return this.schools.filter(school => {
      //   if (school.name.toLowerCase().includes(this.searchSchoolQuery.value.toLowerCase()) && matches < 10) {
      //     matches++
      //     return school
      //   }
      // })
    },
    handleCityChange() {
      try {
        const region = this.regions.filter(region => region.number === this.personalInfo.city);
        if (region.length > 0) {
          this.communes = region[0].communes;
        } else {
          this.communes = []
        }
      } catch (e) {
        this.communes = []
      }
    },
    handleNext() {
      if (!this.formIsAllValid()) {
        this.showValidation = true;
        return;
      }

      localStorage.setItem('step', 3);
      this.$emit('startQuestion');
    },
    formIsAllValid() {
      if (this.personalInfo.name === null
          || this.personalInfo.last_name === null
          || this.personalInfo.age === null
          || this.personalInfo.email === null
          || this.personalInfo.gender === null) {
        return false;
      }
      return true;
    },
    handleCommuneChange() {
      fetch(`https://trayectics.com/app/api/schools/identifier/${this.personalInfo.commune}`, {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',})
      })
          .then(res => res.json())
          .then((res) => {
                this.schools = res.colegios.map(colegio => {
                  return {...colegio, name: colegio.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                });
              }
          )
    }
  },
  watch: {
    personalInfo: {
      handler(newValue) {
        localStorage.setItem('personal_info', JSON.stringify(newValue));
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>