import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue"
import AuthorizationDirector from "@/components/AuthorizationDirector.vue";
import AuthorizationSostenedor from "@/components/AuthorizationSostenedor.vue";
//import HomeRedirect from "@/components/HomeRedirect.vue";

const routes = [
    {
        path: "/",
        name: "HomeRedirect",
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            window.location.href = "https://trayectics.com";
        }
    },
    {
        path: "/encuesta",
        name: "Home",
        component: Home,
    },
    {
        path: "/autorizacion/directores",
        name: "AuthDirector",
        component: AuthorizationDirector,
    },
    {
        path: "/autorizacion/sostenedores",
        name: "AuthSostenedor",
        component: AuthorizationSostenedor,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;