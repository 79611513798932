<template>
  <div class="mt-2 mb-4 card-trayectics p-3 p-lg-5 bg-white rounded-4 position-relative">
    <div v-if="authSostenedorStep === 0">
      <h5 class="mb-3 ps-3 fw-bold mb-5">Invitación a Sostenedor de establecimientos educacionales</h5>
      <div class="d-flex">
        <p class="flex-grow-1 mb-1">Sr/a Sostenedor/a</p>
        <p class="mb-1">Santiago,</p>
      </div>
      <div class="d-flex mb-3">
        <p class="flex-grow-1">Presente</p>
        <p>{{ month }} {{ year }},</p>
      </div>

      <p class="text-justify">
        En calidad de investigador responsable me dirijo a usted para invitar a miembros de las escuelas que usted
        administra a participar en mi estudio "Encuesta Nacional sobre Procesos Evaluativos Contextualizados". Se trata
        de un proyecto CORFO financiado por InnovaChile, línea "Consolida y Expande Innovación - Reactívate", con
        potencial impacto en la mejora de las comunidades educativas al diagnosticar los procesos evaluativos de
        docentes en distintos establecimientos educativos y, de este modo, avanzar en la implementación del decreto 67.
      </p>

      <p class="text-justify">
        El objetivo general de la investigación es realizar un diagnóstico de los procesos evaluativos en escuelas
        chilenas en el contexto de implementación del decreto 67.
      </p>

      <p class="text-justify">
        Se acompañan a esta carta el <a href="https://www.researchgate.net/profile/Macarena-Salas" target="_blank">cv
        resumido del investigador
        responsable</a> y el <a href="#" @click.prevent="authSostenedorStep = 1">resumen
        ejecutivo del proyecto</a>. En
        éste se detallan las principales etapas del estudio y el momento en que se propone involucrar a los
        participantes
        de su comunidad educativa.
      </p>
      <p class="text-justify">
        El proyecto considera la participación de todos los docentes de la escuela. La participación en el estudio
        implica apoyar la realización de las siguientes acciones: facilitar tiempo a los docentes
        para contestar una encuesta online.
      </p>
      <p class="text-justify">
        Para garantizar la correcta conducción del proyecto, cumpliendo los requerimientos éticos de la investigación
        con
        personas, a todos los actores invitados a participar se les solicitará su consentimiento informado,
        antes de involucrarlos en el estudio.
      </p>
      <p class="text-justify">
        Frente a cualquier duda que le suscite la participación en este proyecto, Ud. podrá contactarse conmigo como
        investigadora responsable Macarena Salas Aguayo, email: msalas1@uc.cl, Villagra 70, Providencia, teléfono: +56 9
        8821 8970.
      </p>

      <p class="text-justify">Agradezco de antemano la acogida y valioso apoyo que usted pueda brindar a este
        proyecto.</p>

      <p>Saludos cordiales,</p>

      <div class="d-flex align-items-center gap-2">
        <div></div>
        <div class="text-center flex-grow-1">
          <p class="mb-0">Macarena Salas Aguayo</p>
          <p class="mb-0">Investigadora Responsable</p>
          <p class="mb-0">Pontificia Universidad Católica de Chile</p>
        </div>
        <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="authSostenedorStep = 2">Continuar
        </button>
      </div>

    </div>
    <div v-if="authSostenedorStep === 1">
      <h5 class="mb-3 ps-3 fw-bold mb-5">Resumen ejecutivo del proyecto</h5>
      <p>
        Trayectics es una iniciativa creada por investigadores y docentes, que busca avanzar en la toma de decisiones
        pedagógicas basadas en evidencia. Una de las actividades que nos hemos propuesto para contribuir a la mejora de
        las escuelas es la aplicación de la 1era encuesta de procesos evaluativos contextualizados, que busca
        profundizar en las creencias y prácticas evaluativas de las y los docentes de distintos contextos educativos en
        el contexto de la implementación del decreto 67.
      </p>
      <p>
        La encuesta es totalmente gratuita y presupone una dedicación de 20-30 minutos. Se sugiere que en su escuela
        puedan asegurar un horario protegido por la escuela para contestar la encuesta dentro de la jornada laboral de
        los docentes. Los datos de sus docentes serán totalmente anonimizados, sin ser posible identificar quién
        responde la encuesta.
      </p>

      <p>
        Si acepta participar obtendrá un informe por escuela con información accionable para sus equipos directivos.
      </p>

      <p>
        En cuanto contemos con los resultados le invitaremos a participar de la difusión de los hallazgos a nivel
        nacional.
      </p>

      <p>
        Le invitamos a hacerse parte de esta iniciativa que permitirá contar con información relevante para mejorar las
        prácticas docentes en evaluación a nivel nacional.
      </p>

      <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="authSostenedorStep = 0">Ir atrás
        </button>
      </div>

    </div>

    <div v-if="authSostenedorStep === 2">
      <h5 class="mb-3 ps-3 fw-bold mb-1">Autorización</h5>
      <p class="mb-5 ps-3">Ingrese los datos solicitados y continúe para revisar la autorización.</p>

      <div class="row p-1 mb-3">

        <div class="col-12 mb-3">
          <label class="form-label">Nombre del Representante Legal</label>
          <input type="text" class="form-control" placeholder="Ingrese su nombre y apellido"
                 v-model="full_name"
                 :class="{ 'is-invalid': (full_name === '' && showValidation) }">
        </div>

        <div class="col-12 mb-3">
          <label class="form-label">Nombre del Sostenedor (Servicio Local, Corporación, etc)</label>
          <input type="text" class="form-control" placeholder="Ingrese el nombre del Sostenedor"
                 v-model="establishment"
                 :class="{ 'is-invalid': (establishment === '' && showValidation) }">
        </div>

        <div class="col-12 mb-3">
          <label class="form-label">Correo electrónico de contacto</label>
          <input type="email" class="form-control" placeholder="Ingrese la dirección de correo electrónico"
                 v-model="email"
                 :class="{ 'is-invalid': (email === '' && showValidation) }">
        </div>

        <div class="col-3 mb-3">
          <label class="form-label">Fecha</label>
          <input type="text" class="form-control disabled"
                 v-model="currentDate"
                 disabled>
        </div>

      </div>

      <div class="">
        <div class="d-flex flex-row-reverse gap-3">
          <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="handleAuthorization">Continuar
          </button>
          <button class="btn btn-warning btn-trayectics-secondary" type="button"
                  @click.prevent="authSostenedorStep = 1">
            Ir atrás
          </button>
        </div>
      </div>

    </div>

    <div v-if="authSostenedorStep === 3">
      <h5 class="mb-3 ps-3 fw-bold mb-5">Carta de Autorización</h5>

      <p>
        Yo <strong>{{ full_name }}</strong>, Sostenedor/a de <strong>{{ establishment }}</strong>, autorizo y apoyo la
        participación de este establecimiento
        en el proyecto "Encuesta Nacional sobre Procesos Evaluativos Contextualizados".
        El propósito y naturaleza de la investigación me han sido explicados por la investigadora responsable, Sra.
        Macarena Salas Aguayo.
      </p>

      <p>
        Para efectos de dar curso a esta autorización, el investigador responsable cuenta con la certificación
        previa de un Comité Ético Científico que corresponde de acuerdo a la normativa legal vigente.
      </p>

      <p>
        La investigación constituirá un aporte al diagnosticar los procesos evaluativos de docentes en distintos
        establecimientos educativos para la mejora de las comunidades educativas, generando información accionable
        para avanzar en la implementación del decreto 67.
      </p>

      <p>
        Me han quedado claras las implicancias de la participación de nuestro establecimiento en el proyecto y se
        me ha informado de la posibilidad de contactar ante cualquier duda al investigador responsable del estudio
        Sra. Macarena Salas Aguayo, email: msalas1@uc.cl, Villagra 70, Providencia,
        teléfono: +56 9 8821 8970.
      </p>

      <div class="">
        <div class="d-flex flex-row-reverse gap-3">
          <button class="btn btn-primary btn-trayectics" type="button"
                  @click.prevent="handleConfirmAuthorization"
                  :disabled="isSending">Confirmar autorización</button>
          <button class="btn btn-warning btn-trayectics-secondary" type="button"
                  @click.prevent="authSostenedorStep = 0">
            No autorizar
          </button>
        </div>
      </div>

    </div>

    <div v-if="authSostenedorStep === 4">
      <div class="text-center mb-3 gap-4">
        <h4 class="mt-5">Autorización Exitosa</h4>
        <p>Recibirás un correo electrónico de confirmación a la dirección ingresada. Muchas gracias.</p>
        <button class="btn btn-primary btn-trayectics" type="button" @click.prevent="handleFinish">Ir al inicio</button>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "AuthorizationSostenedor",
  data() {
    return {
      authSostenedorStep: 0,
      showValidation: false,
      month: 'Junio',
      year: '',
      full_name: '',
      establishment: '',
      email: '',
      currentDate: '',
      isSending: false
    }
  },
  methods: {
    currentMonth() {
      const month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
        "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      const d = new Date();
      this.month = month[d.getMonth()];
      this.year = d.getFullYear();
      const day = d.getDate()
      const monthNumber = d.getMonth();
      this.currentDate = `${day}/${monthNumber}/${this.year}`;
    },
    formIsAllValid() {
      if (this.full_name === '' || this.establishment === '' || this.email === '') {
        return false;
      }
      return true;
    },
    handleAuthorization() {
      if (this.formIsAllValid()) {
        this.showValidation = false;
        this.authSostenedorStep = 3;
      } else {
        this.showValidation = true;
      }
    },
    handleConfirmAuthorization() {
      const body = {email: this.email, establishment: this.establishment, auth_type: 2, full_name: this.full_name};
      this.isSending = true;
      fetch(`https://trayectics.com/app/api/save-authorization`, {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',}),
        body: JSON.stringify(body)
      })
          .then(res => res.json())
          .then(() => {
                this.authSostenedorStep = 4;
              }
          )
          .catch(() => {
            this.$notify("Ha ocurrido un error al procesar la solicitud. (101)");
          })
          .finally(() => {
            this.isSending = false;
          })
    },
    handleFinish() {
      this.authSostenedorStep = 0;
    }
  },
  mounted() {
    this.currentMonth()
  }
}
</script>

<style>

</style>